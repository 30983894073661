import {AppConfig} from "./type";

type Optional<T, K extends keyof T> = Pick<Partial<T>, K> & Omit<T, K>;
type KeysWithFallbackValue = "mocksEnabled";
type RequiredConfig = Optional<AppConfig, KeysWithFallbackValue>;

const defaultConfig: Pick<AppConfig, KeysWithFallbackValue> = {
    mocksEnabled: false,
};

export function defineConfig(config: RequiredConfig): AppConfig {
    return {
        ...defaultConfig,
        ...config,
    };
}

function normalizeUrl(url: string): string {
    // Use a regular expression to replace multiple consecutive slashes with a single slash
    return url.replace(/([^:])\/\/+/g, '$1/');
}

export function getUrl(urlRoot: string, location?: string) {
    const version: string = process.env['API_VERSION'] ?? 'v1';
    const loc: string = location ?? "";
    const url = normalizeUrl(`${urlRoot}/${loc}/api/${version}`);
    console.log(`url = ${url}`);
    return url
}
