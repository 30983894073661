import React, {CSSProperties, FC} from "react";
import {useAuth} from "../../config/AuthContext";
import {Button, Checkbox, Flex, Typography} from "antd";
import {useGlobal} from "../../config/GlobalContext";
import {useNavigate} from "react-router-dom";
import "./SBHeader.css";

const SBHeader: FC = () => {

    const {authUser, isLoggedIn, setIsLoggedIn, setIsAdmin, setAuthUser} = useAuth();
    const {showAll, setShowAll} = useGlobal();
    const navigate = useNavigate();

    const logIn = (e: any) => {
        e.preventDefault();
        navigate("/users/login");
    }

    const logOut = (e: any) => {
        e.preventDefault();
        setIsLoggedIn(false);
        setIsAdmin(false);
        setAuthUser({idt: -1, username: '', role: ''});
    }

    const swapShowAll = (e: any) => {
        setShowAll(!showAll);
    }

    const boxStyle: CSSProperties = {
        width: '100%',
        height: 120,
        padding: '10px',
    };

    const paragraph: CSSProperties = {
        color: 'bisque',
    }

    const justify = 'space-between';
    const alignItems = 'flex-start';

    return (
        <Flex gap="middle" align="start">
            <Flex style={boxStyle} justify={justify} align={alignItems}>
                {isLoggedIn ?
                    (
                        <Button onClick={(e) => logOut(e)}>Déconnexion</Button>
                    ) :
                    (<Button onClick={(e) => logIn(e)}>Connexion</Button>)
                }
                {isLoggedIn ?
                    (
                        <Typography style={paragraph}>
                            Bonjour et bienvenue {authUser.username}
                        </Typography>
                    ) :
                    (<></>)
                }
                <Checkbox onClick={(e) => swapShowAll(e)}>
                    Montrer les matchs terminés
                </Checkbox>
            </Flex>
        </Flex>
    )
}

export default SBHeader;