import React, {FC, useEffect, useState} from 'react';
import AxiosServices from "../Services/axiosServices";
import MatchComponent from "./MatchComponent";
import CurrentData from "../config/CurrentData";
import {IMatch} from "../Interfaces/IMatch";
import {useAuth} from "../config/AuthContext";
import {IUserBets} from "../Interfaces/IUserBets";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, RootState} from "../store";
import {fetchUserBets} from "./betsSlice";

interface Props {
}

const Matchs: FC<Props> = (props) => {

    const [matches, setMatches] = useState<IMatch[]>([]);
    const {authUser, isLoggedIn} = useAuth()
    const dispatch = useDispatch<AppDispatch>();
    const userBets = useSelector((state: RootState) => state.bets.userBets);

    useEffect(() => {
        AxiosServices.getMatches(setMatches, CurrentData.CmpEdt_Idt)
            .then()
            .catch(err => {
                console.log(err);
            });

        if (isLoggedIn && authUser) {
            dispatch(fetchUserBets({ competition: CurrentData.CmpEdt_Idt, usr: authUser?.username }))
        }
    }, [isLoggedIn, authUser, dispatch]);

    return <div className="overview">
        <ul>
            {matches.map((match: IMatch) => {
                const userBet = userBets.find((bet: IUserBets)=> bet.num === match.Num);
                // console.log(`${match.Num} - ${match.DteHre} - ${HasMatchStarted(match)}`);
                return (<MatchComponent match={match}/>);
            })}
        </ul>
    </div>;
}

export default Matchs;