import {AppConfig} from './type';
import {createLocalConfig} from "./envs/local";
import {createProdConfig} from "./envs/prod";
import {createStagingConfig} from "./envs/staging";

const appConfig: AppConfig = getConfig();

function getConfig(): AppConfig {
    switch (process.env['REACT_APP_ENV']) {
        case "production":
            return createProdConfig(process.env['REACT_APP_LOCATION'] ?? "none");
        case "staging":
            return createStagingConfig();
        case "local":
            return createLocalConfig();
        default:
            throw new Error(`Invalid REACT_APP_ENV "${process.env['REACT_APP_ENV']}"`);
    }
}

export default appConfig;
