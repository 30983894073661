function About() {
    return (
        <div className="overview">
            <div className="about">

                <h1>Fonctionnement de Sports-Bets</h1>
                <h2>2 groupes de paris</h2>

                <h3>Résultats finaux de la compétition - Paris à effectuer avant le début de la compétition (Cf.
                    Question
                    bonus ci dessous)</h3>
                <ul>
                    <li>1/ Qui gagnera le Championnat d'Europe 2016 ?</li>
                    <li>2/ Qui sera Finaliste vaincu ?</li>
                    <li>3/ Qui sortira vainqueur de chacun des groupes A à H (exemple à 8 groupes)</li>
                    <li>4/ Qui marquera le plus grand nombre de buts (meilleure attaque) ?</li>
                    <li>5/ Qui encaissera le plus petit nombre de buts (meilleure défense) ?</li>
                </ul>
                Les Paris 4 et 5 seront calculés sur les 4 dernières équipes (les 4 demis-finalistes)

                <h3>
                    Paris pendant la compétition</h3>
                <p>Pour ces paris, il suffit de parier sur le résultat attendu de chacun des matches, 1/4 d'heure avant
                    que
                    celui-ci ne commence (ou jusqu'au début du match).
                </p>

                <h4>Décompte des points</h4>
                <ul>
                    <li>Pari Incorrect : 1 point. (ceci pour donner un bénéfice par rapport à celui qui aurait oublié de
                        poster son pari)... Exemple
                        un pari = (2 : 1) pour un résultat de (1 : 2)
                    </li>
                    <li>
                        Tendance :
                        5 points (2 : 1 pour 3 : 1)
                    </li>
                    <li>
                        Différence :
                        9 points (2 : 1 pour 4 : 3)
                    </li>
                    <li>
                        Exact :
                        20 points (2 : 1 pour 2 : 1)
                    </li>
                </ul>

                <h4>
                    Cas particulier :
                </h4>
                <p>En cas de match nul bien pronostiqué, on prend le nombre point de la différence de buts (i.e 9
                    points) ou
                    seulement la tendance (i.e. 5 points) (1 : 1 pour 2 : 2)</p>
                <p>Lors des phases à élimination directe, on gardera le résultat en fin de prolongation (on peut donc
                    avoir
                    un
                    match nul !)</p>
                <h4>
                    Bonus Score :
                </h4>
                <ul>
                    <li>+2 points si le nombre de buts de l'équipe "domicile" est correct.</li>
                    <li>+2 points si le nombre de buts de l'équipe "extérieure" est correct.</li>
                </ul>
                <p> Ainsi, même avec un résultat incorrect, on pourra grappiller des points.</p>
                <p> Par exemple, si le résultat est 2 : 1 et que le pari était à 0 : 1, on gagne quand même 2 points
                    pour
                    bon score "extérieur". </p>
                <p>
                    Ceci fait qu'un pari "Exact" permet de gagner 24 points ! 20 + 2 + 2 !
                </p>

                <h4>Questions Bonus</h4>
                Attribués en fin de championnat, ces points peuvent bouleverser le classement jusque là établi.
                <ul>
                    <li>Qui gagnera le Championnat ? 50 points</li>
                    <li>Qui sera Finaliste vaincu ? 37 points</li>
                    <li>Qui sortira vainqueur de chacun des groupes A à H ? 22 points</li>
                    <li>Qui marquera le plus grand nombre de buts (meilleure attaque) ? 11 points</li>
                    <li>Qui encaissera le plus petit nombre de buts (meilleure défense) ? 11 points</li>
                </ul>

                Tout ceci pourra être "paramétrable" en terme de nombre de points et de choix sur le cas "particulier"
                du
                match nul.
            </div>

        </div>);
}

export default About;
