import {Context, createContext, ReactNode, useContext, useState} from "react";

type TValue = {
    showAll: boolean,
    setShowAll: (value: boolean) => void,
}

const DValue: TValue = {
    showAll: true,
    setShowAll: () => {
    },
}

const GlobalContext: Context<TValue> = createContext(DValue);

export function useGlobal(): TValue {
    return useContext(GlobalContext);
}

export function GlobalProvider({children}: { children: ReactNode }) {
    const [showAll, setShowAll] = useState(false);
    const value: TValue = {showAll, setShowAll};

    return (
        <GlobalContext.Provider value={value}>{children}</GlobalContext.Provider>
    )
}

// export default GlobalContext;