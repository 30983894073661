import React, {FC} from "react";
import {useAuth} from "../../config/AuthContext";
import appConfig from "../../config";
import {Trans} from "react-i18next";

const SBFooter: FC = () => {

    const {setAuthUser, setIsLoggedIn, isLoggedIn, isAdmin} = useAuth();

    return (
        <>
            {isLoggedIn && isAdmin ? (<>Backend {appConfig.env} - API {appConfig.apiURL} - </>) : (
                <></>)
            }
            <Trans i18nKey={"Copyright"}/>
        </>
    )
}

export default SBFooter;