import {useEffect, useState} from "react";
import AxiosServices from "../../Services/axiosServices";
import CurrentData from "../../config/CurrentData";
import {IMatch} from "../../Interfaces/IMatch";
import {IClassement} from "../../Interfaces/IClassement";
import {Table} from "antd";

const Classement = () => {

    const [classement, setClassement] = useState<IClassement[]>([]);

    useEffect(() => {
        AxiosServices.getClassement(setClassement, CurrentData.CmpEdt_Idt)
            .then()
            .catch(err => {
                console.log(err);
            });
    }, []);

    const columns = [
        {
            title: 'Rang',
            dataIndex: 'Rang',
            key: 'Rang',
        },
        {
            title: 'Parieur',
            dataIndex: 'Parieur',
            key: 'Parieur',
        },
        {
            title: 'Total',
            dataIndex: 'Total',
            key: 'Total',
        },
        {
            title: 'Score exact',
            dataIndex: 'Score_exact',
            key: 'Score_exact',
        },
        {
            title: 'Différence de but',
            dataIndex: 'Différence_de_but',
            key: 'Différence_de_but',
        },
        {
            title: 'Bon pronostic',
            dataIndex: 'Bon_pronostic',
            key: 'Bon_pronostic',
        },
        {
            title: 'Buts Domicile',
            dataIndex: 'Buts_Domicile',
            key: 'Buts_Domicile',
        },
        {
            title: 'Buts Extérieur',
            dataIndex: 'Buts_Extérieur',
            key: 'Buts_Extérieur',
        },
        {
            title: 'Pari effectué',
            dataIndex: 'Pari_effectué',
            key: 'Pari_effectué',
        },
    ];


    return (
        <Table dataSource={classement}
               columns={columns}
               pagination={false}
               footer={() => '--'}
        />
    );
}

export default Classement;