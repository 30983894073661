import axios, {AxiosInstance} from 'axios';
import appConfig from "../config";

const instance: AxiosInstance = axios.create({
    baseURL: appConfig.apiURL,
    headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
    },
    withCredentials: true
});

export const setAuthToken = (token: string) => {
    console.log('setAuthToken', token);
    if (token) {
        try {
            instance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
        }
        catch (error) {
            console.error(error);
        }
    } else
        delete instance.defaults.headers.common["Authorization"];
}

export default instance;