import {Card, Checkbox, Grid, TextField, Box, styled, useTheme} from "@mui/material";
import {useNavigate, NavLink} from "react-router-dom";
import React, {useState} from "react";
import {useAuth} from "../../config/AuthContext";
import {LoadingButton} from "@mui/lab";
import {Formik} from "formik";
import * as Yup from "yup";
import {Paragraph} from "../Typography";
import AxiosServices from "../../Services/axiosServices";
import CurrentData from "../../config/CurrentData";
import {IUserBets} from "../../Interfaces/IUserBets";

// STYLED COMPONENTS
const FlexBox = styled(Box)(() => ({
    display: "flex"
}));

const ContentBox = styled("div")(() => ({
    height: "100%",
    padding: "32px",
    position: "relative",
    background: "rgba(0, 0, 0, 0.01)"
}));

const StyledRoot = styled("div")(() => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#203923",
    minHeight: "100% !important",
    "& .card": {
        maxWidth: 800,
        minHeight: 400,
        margin: "1rem",
        display: "flex",
        borderRadius: 12,
        alignItems: "center"
    },

    ".img-wrapper": {
        height: "100%",
        minWidth: 320,
        display: "flex",
        padding: "2rem",
        alignItems: "center",
        justifyContent: "center"
    }
}));

// initial login credentials
const initialValues = {
    email: "",
    password: "",
    remember: true
};

// form field validation schema
const validationSchema = Yup.object().shape({
    password: Yup.string()
        .min(6, "Password must be 6 character length")
        .required("Password is required!"),
    email: Yup.string().email("Invalid Email address").required("Email is required!")
});

const Login_001 = () => {
    const theme = useTheme();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [bets, setBets] = useState<IUserBets[]>([]);

    const {login, authUser} = useAuth();

    const handleFormSubmit = async (values: any) => {
        setLoading(true);
        try {
            login(values)
            AxiosServices.getUserBets(setBets, CurrentData.CmpEdt_Idt, authUser.username).then(r => {
                console.log('getUserBets result =>', r);
            })
            navigate("/");
        } catch (e) {
            setLoading(false);
        }
    }

    return (
        <StyledRoot>
            <Card className="card">
                <Grid container>
                    <Grid item sm={6} xs={12}>
                        <div className="img-wrapper">
                            <img src="/assets/images/illustrations/72bc6966.jpg" width="100%" alt=""/>
                        </div>
                    </Grid>

                    <Grid item sm={6} xs={12}>
                        <ContentBox>
                            <Formik
                                onSubmit={handleFormSubmit}
                                initialValues={initialValues}
                                validationSchema={validationSchema}>
                                {({values, errors, touched, handleChange, handleBlur, handleSubmit}) => (
                                    <form onSubmit={handleSubmit}>
                                        <TextField
                                            fullWidth
                                            size="small"
                                            type="email"
                                            name="email"
                                            label="Email"
                                            variant="outlined"
                                            onBlur={handleBlur}
                                            value={values.email}
                                            onChange={handleChange}
                                            helperText={touched.email && errors.email}
                                            error={Boolean(errors.email && touched.email)}
                                            sx={{mb: 3}}
                                        />

                                        <TextField
                                            fullWidth
                                            size="small"
                                            name="password"
                                            type="password"
                                            label="Mot de passe"
                                            variant="outlined"
                                            onBlur={handleBlur}
                                            value={values.password}
                                            onChange={handleChange}
                                            helperText={touched.password && errors.password}
                                            error={Boolean(errors.password && touched.password)}
                                            sx={{mb: 1.5}}
                                        />

                                        <FlexBox justifyContent="space-between">
                                            <FlexBox gap={1}>
                                                <Checkbox
                                                    size="small"
                                                    name="remember"
                                                    onChange={handleChange}
                                                    checked={values.remember}
                                                    sx={{padding: 0}}
                                                />

                                                <Paragraph>Remember Me</Paragraph>
                                            </FlexBox>

                                            <NavLink
                                                to="/session/forgot-password"
                                                style={{color: theme.palette.primary.main}}>
                                                Mot de passe oublié ?
                                            </NavLink>
                                        </FlexBox>

                                        <LoadingButton
                                            type="submit"
                                            color="primary"
                                            loading={loading}
                                            variant="contained"
                                            sx={{my: 2}}>
                                            Login
                                        </LoadingButton>

                                        <Paragraph>
                                            Pas encore inscrit ?
                                            <NavLink
                                                to="/users/register"
                                                style={{color: theme.palette.primary.main, marginLeft: 5}}>
                                                Inscription
                                            </NavLink>
                                        </Paragraph>
                                    </form>
                                )}
                            </Formik>
                        </ContentBox>
                    </Grid>
                </Grid>
            </Card>
        </StyledRoot>
    );
}
export default Login_001;