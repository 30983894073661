import React, {FC, useState} from "react";
import {Box, styled, Grid, TextField, useTheme, Checkbox} from "@mui/material";
import {Card, Modal} from "antd";
import {NavLink, useNavigate} from "react-router-dom";
import {Formik} from "formik";
import AxiosServices from "../../Services/axiosServices";
import * as Yup from "yup";
import {LoadingButton} from "@mui/lab";
import {Paragraph} from "../Typography";
import {IRegisterData} from "../../Interfaces/IRegisterData";

// STYLED COMPONENTS
const FlexBox = styled(Box)(() => ({
    display: "flex"
}));

const ContentBox = styled("div")(() => ({
    height: "100%",
    padding: "32px",
    position: "relative",
    background: "rgba(0, 0, 0, 0.01)"
}));

const StyledRoot = styled("div")(() => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#203923",
    minHeight: "100% !important",
    "& .card": {
        maxWidth: 800,
        minHeight: 400,
        margin: "1rem",
        display: "flex",
        borderRadius: 12,
        alignItems: "center"
    },

    ".img-wrapper": {
        height: "100%",
        minWidth: 320,
        display: "flex",
        padding: "2rem",
        alignItems: "center",
        justifyContent: "center"
    }
}));

const initialValues: IRegisterData = {
    Title: "",
    firstName: "",
    lastName: "",
    username: "",
    email: "",
    password: "",
    passwordConfirmation: "",
    acceptTerms: true
};

const validationSchema = Yup.object().shape({
    Title: Yup.string()
        .required("La civilité est requise !"),
    firstName: Yup.string()
        .required("Le prénom est requis !"),
    lastName: Yup.string()
        .required("Le nom est requis !"),
    username: Yup.string()
        .min(2, "Le nom d'utilisateur doit posséder au moins 2 caractères.")
        .required("Le nom d'utilisateur est requis !"),
    email: Yup.string()
        .email("Adresse mail invalide")
        .required("L'adresse mail est requis !"),
    password: Yup.string()
        .min(6, "Le password doit posséder au moins 6 caractères.")
        .required("Le password est requis !"),
    passwordConfirmation: Yup.string()
        .min(6, "Le password doit posséder au moins 6 caractères.")
        .equals([Yup.ref('password')], "Les 2 passwords doivent être identiques.")
        .required("Le password est requis !"),
});


const UserRegister: FC = () => {
    const theme = useTheme();
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const [confirmLoading, setConfirmLoading] = useState(false);
    const [modalText, setModalText] = useState('');
    const [open, setOpen] = useState(false);

    const Register = async (values: any) => {
        AxiosServices.RegisterAccount(values)
            .then(async (response) => {
                setModalText(`Le compte ${values.username} a bien été créé...<br>Un mail à l’adresse<br><b>${values.email}</b><br>a été envoyé pour la validation de votre compte.`);
                await showModal()
            })
            .catch(err => {
                console.log(err)
            });
    }

    const onFinish = (values: any) => {
        try {
            setLoading(true);
            Register(values)
                .then(() => {
                });
        } catch (e) {
        }
        finally {
            setLoading(false);
        }
    }

    const showModal = async () => {
        setOpen(true);
    };

    const handleOk = () => {
        setModalText('Veuillez patienter pendant la redirection vers la page de connexion...');
        setConfirmLoading(true);
        setTimeout(() => {
            setOpen(false);
            setConfirmLoading(false);
            navigate('/users/login'); // Navigate to another page
        }, 2000);
    };
    const handleCancel = () => {
        setOpen(false);
    };

    return (
        <>
            <StyledRoot>
                <Card className="card">
                    <Grid container>
                        <Grid item sm={6} xs={12}>
                            <div className="img-wrapper">
                                <img src="/Inscription.jpg" width="100%" alt=""/>
                            </div>
                        </Grid>

                        <Grid item sm={6} xs={12}>
                            <ContentBox>
                                <Formik
                                    onSubmit={onFinish}
                                    initialValues={initialValues}
                                    validationSchema={validationSchema}>

                                    {({values, errors, touched, handleChange, handleBlur, handleSubmit}) => (
                                        <form onSubmit={handleSubmit}>

                                            <TextField
                                                fullWidth
                                                size="small"
                                                name="username"
                                                label="Username"
                                                variant="outlined"
                                                onBlur={handleBlur}
                                                value={values.username}
                                                onChange={handleChange}
                                                helperText={touched.username && errors.username}
                                                error={Boolean(errors.username && touched.username)}
                                                sx={{mb: 3}}
                                            />

                                            <Grid item xs={12}>
                                                <TextField
                                                    select
                                                    fullWidth
                                                    size="small"
                                                    name="Title"
                                                    label="Civilité"
                                                    variant="outlined"
                                                    onBlur={handleBlur}
                                                    value={values.Title}
                                                    onChange={handleChange}
                                                    helperText={touched.Title && errors.Title}
                                                    error={Boolean(errors.Title && touched.Title)}
                                                    sx={{mb: 3}}
                                                    SelectProps={{
                                                        native: true,
                                                    }}
                                                >
                                                    <option value=""></option>
                                                    <option value="1">Monsieur</option>
                                                    <option value="3">Mademoiselle</option>
                                                    <option value="2">Madame</option>
                                                </TextField>
                                            </Grid>

                                            <Grid container spacing={2}>
                                                <Grid item xs={6}>
                                                    <TextField
                                                        fullWidth
                                                        size="small"
                                                        name="firstName"
                                                        label="Prénom"
                                                        variant="outlined"
                                                        onBlur={handleBlur}
                                                        value={values.firstName}
                                                        onChange={handleChange}
                                                        helperText={touched.firstName && errors.firstName}
                                                        error={Boolean(errors.firstName && touched.firstName)}
                                                        sx={{mb: 3}}
                                                    />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <TextField
                                                        fullWidth
                                                        size="small"
                                                        name="lastName"
                                                        label="Nom"
                                                        variant="outlined"
                                                        onBlur={handleBlur}
                                                        value={values.lastName}
                                                        onChange={handleChange}
                                                        helperText={touched.lastName && errors.lastName}
                                                        error={Boolean(errors.lastName && touched.lastName)}
                                                        sx={{mb: 3}}
                                                    />
                                                </Grid>
                                            </Grid>

                                            <TextField
                                                fullWidth
                                                size="small"
                                                type="email"
                                                name="email"
                                                label="Email"
                                                variant="outlined"
                                                onBlur={handleBlur}
                                                value={values.email}
                                                onChange={handleChange}
                                                helperText={touched.email && errors.email}
                                                error={Boolean(errors.email && touched.email)}
                                                sx={{mb: 3}}
                                            />

                                            <TextField
                                                fullWidth
                                                size="small"
                                                name="password"
                                                type="password"
                                                label="Mot de passe"
                                                variant="outlined"
                                                onBlur={handleBlur}
                                                value={values.password}
                                                onChange={handleChange}
                                                helperText={touched.password && errors.password}
                                                error={Boolean(errors.password && touched.password)}
                                                sx={{mb: 1.5}}
                                            />

                                            <TextField
                                                fullWidth
                                                size="small"
                                                name="passwordConfirmation"
                                                type="password"
                                                label="Confirmation"
                                                variant="outlined"
                                                onBlur={handleBlur}
                                                value={values.passwordConfirmation}
                                                onChange={handleChange}
                                                helperText={touched.passwordConfirmation && errors.passwordConfirmation}
                                                error={Boolean(errors.passwordConfirmation && touched.passwordConfirmation)}
                                                sx={{mb: 1.5}}
                                            />

                                            <FlexBox justifyContent="space-between">
                                                <FlexBox gap={1}>
                                                    <Checkbox
                                                        size="small"
                                                        name="acceptTerms"
                                                        onChange={handleChange}
                                                        checked={values.acceptTerms}
                                                        sx={{padding: 0}}
                                                    />

                                                    <Paragraph>Accepter les conditions de jeu</Paragraph>
                                                </FlexBox>

                                                {/*<NavLink*/}
                                                {/*    to="/session/forgot-password"*/}
                                                {/*    style={{color: theme.palette.primary.main}}>*/}
                                                {/*    Mot de passe oublié ?*/}
                                                {/*</NavLink>*/}
                                            </FlexBox>

                                            <LoadingButton
                                                type="submit"
                                                color="primary"
                                                loading={loading}
                                                variant="contained"
                                                sx={{my: 2}}>
                                                Enregistrement
                                            </LoadingButton>

                                            <Paragraph>
                                                Déjà inscrit ?
                                                <NavLink
                                                    to="/users/login"
                                                    style={{color: theme.palette.primary.main, marginLeft: 5}}>
                                                    Connexion
                                                </NavLink>
                                            </Paragraph>
                                        </form>
                                    )}
                                </Formik>
                            </ContentBox>
                        </Grid>
                    </Grid>
                </Card>
            </StyledRoot>

            <Modal
                title="Validation de l'inscription"
                open={open}
                onOk={handleOk}
                confirmLoading={confirmLoading}
                onCancel={handleCancel}
            >
                <div dangerouslySetInnerHTML={{__html: modalText}}/>
            </Modal>
        </>
    );
};

export default UserRegister;