import {InputNumber, InputNumberProps} from "antd";
import {FC, useEffect, useState} from "react";

type Props = {
    callback: (value: any) => void,
    disable?: boolean,
    score?: number | null,
    // saisie?: ISaisie
};

const ScoreInput: FC<Props> = (props: Props) => {
    const [inputValue, setInputValue] = useState<number | null>(props.score ?? null);

    useEffect(() => {
        setInputValue(props.score ?? null);
    }, [props.score]);

    const onChange: InputNumberProps['onChange'] = (value) => {
        setInputValue(value as number);
        props.callback(value);
    };
    return (
                <InputNumber onChange={onChange}
                             min={0}
                             max={20}
                             value={inputValue}
                             style={{width: '3rem', alignItems: 'center'}}
                             disabled={props.disable}
                />
    )
}
export default ScoreInput;