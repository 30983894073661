import {FC, useEffect, useState} from "react";
import {IGroup} from "../Interfaces/IGroup";
import AxiosServices from "../Services/axiosServices";
import CurrentData from "../config/CurrentData";

const Groups: FC = () => {
    const [groups, setGroups] = useState<IGroup[]>([]);

    useEffect(() => {
        AxiosServices.getGroups(setGroups, CurrentData.CmpEdt_Idt)
            .then()
            .catch(err => {
                console.log(err);
            });
    }, []);

    return (
        <div className={"overview"}>
            <ul>
                {groups.map((group: IGroup) => (
                    <ul>Groupe {group.Grp_Cod}</ul>
                ))}
            </ul>
        </div>
    )
}
export default Groups;