import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {IUserBets} from '../Interfaces/IUserBets';
import AxiosServices from "../Services/axiosServices";

// Define the initial state
interface BetsState {
    userBets: IUserBets[];
    loading: boolean;
    error: string | null;
}

const initialState: BetsState = {
    userBets: [],
    loading: false,
    error: null,
};

// Create a thunk that uses the getUserBets method
export const fetchUserBets = createAsyncThunk(
    'bets/fetchUserBets',
    async ({competition, usr}: { competition: number; usr?: string }, {rejectWithValue}) => {
        try {
            return await new Promise<IUserBets[]>((resolve, reject) => {
                console.log('fetchUserBets', competition, usr);
                AxiosServices.getUserBets(resolve, competition, usr).catch(reject);
            });
        } catch (error: any) {
            return rejectWithValue(error.response ? error.response.data : 'Failed to fetch');
        }
    }
);

const betsSlice = createSlice({
    name: 'bets',
    initialState,
    reducers: {
        // Example action if you need to add/update bets manually
        setBets(state, action: PayloadAction<IUserBets[]>) {
            state.userBets = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchUserBets.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchUserBets.fulfilled, (state, action: PayloadAction<IUserBets[]>) => {
                state.loading = false;
                state.userBets = action.payload;
            })
            .addCase(fetchUserBets.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message || 'Failed to fetch bets';
            });
    },
});

export const {setBets} = betsSlice.actions;
export default betsSlice.reducer;
