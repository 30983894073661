import React, {FC} from 'react';
import './App.css';
import {Flex, Layout} from 'antd';
import {Route, Routes} from 'react-router-dom';
import Sidebar from "./components/Sidebar";
import SBHeader from "./components/Layout/SBHeader";
import SBFooter from "./components/Layout/SBFooter";
import {IRoute, routes} from "./Data/SidebarData";
import {I18nextProvider} from "react-i18next";
import i18n from "./i18n";
import {AuthProvider, useAuth} from "./config/AuthContext";
import {GlobalProvider} from "./config/GlobalContext";

const {Header, Content, Footer, Sider} = Layout;

const layoutStyle = {
    overflow: 'hidden',
    width: '100vw',
    height: '100vh',
};

const footerStyle: React.CSSProperties = {
    textAlign: 'right',
    color: '#fff',
    backgroundColor: '#203923',
    maxWidth: '100%',
    maxHeight: '1.2rem',
    height: '1.2rem',
    padding: '0px',
};

const siderStyle: React.CSSProperties = {
    textAlign: 'left',
    paddingTop: '5rem',
    paddingLeft: '10px',
    color: '#001122',
    backgroundColor: '#203923',
};

const headerStyle: React.CSSProperties = {
    textAlign: 'left',
    color: '#fff',
    height: 64,
    paddingInline: 48,
    lineHeight: '64px',
    backgroundColor: '#203923',
};

const contentStyle: React.CSSProperties = {
    textAlign: 'center',
    minHeight: 130,
    lineHeight: '2rem',
    color: '#fff',
    backgroundColor: '#203923',
};

const App: FC = () => {

    //check jwt token
    const {token, setToken} = useAuth();
    if (token) {
        console.log(`App : ${token}`)
        setToken(token);
    }

    return (
        <I18nextProvider i18n={i18n}>
            <AuthProvider>
                <GlobalProvider>
                    <Flex gap="middle" wrap>
                        <Layout hasSider={true} style={layoutStyle}>
                            <Sider width="15rem" style={siderStyle}>
                                <Sidebar/>
                            </Sider>
                            <Layout>
                                <Header style={headerStyle}>
                                    <SBHeader/>
                                </Header>
                                <Content style={contentStyle}>
                                    <Routes>
                                        {routes.map((item: IRoute) => {
                                            return <Route path={item.key} element={item.reactElement}></Route>
                                        })}
                                    </Routes>
                                </Content>
                                <Footer style={footerStyle}>
                                    <SBFooter/>
                                </Footer>
                            </Layout>
                        </Layout>
                    </Flex>
                </GlobalProvider>
            </AuthProvider>
        </I18nextProvider>
    );
}

export default App;
