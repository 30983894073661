import i18next from "i18next";
import {initReactI18next} from 'react-i18next';
import translationArabic from "./Translations/translation.ar.json";
import translationDeutsch from "./Translations/translation.de.json";
import translationEnglish from "./Translations/translation.en.json";
import translationFrench from "./Translations/translation.fr.json";

const resources = {
    en: {
        translation: translationEnglish,
    },
    fr: {
        translation: translationFrench,
    },
    ar: {
        translation: translationArabic,
    },
    de: {
        translation: translationDeutsch,
    }
}

i18next
    .use(initReactI18next)
    .init({
        resources,
        lng: 'fr', // default FR
    });

export default i18next;
