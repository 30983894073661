import { configureStore } from '@reduxjs/toolkit';
import betsReducer from './components/betsSlice';

const store = configureStore({
    reducer: {
        bets: betsReducer,
    },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;