import appConfig from "../config";
import axiosInstance from './axiosConfig';
import axios from "axios";
import {IMatch} from "../Interfaces/IMatch";

class AxiosServices {

    static baseURL: string = appConfig.apiURL;
    static usrApiURL: string = `${appConfig.apiURL}/user`
    private static usrbetApiURL: string = `${appConfig.apiURL}/usrbet`

    static async validateToken(token: string) {
        console.log(`token = ${token}`);
        const baseURL = `${this.usrApiURL}/check-email?token=${token}`;
        try {
            const response = await axiosInstance.post(baseURL);
            return {data: response, error: null};
        } catch (error: unknown) {
            let errorMessage: string;
            if (axios.isAxiosError(error)) {
                errorMessage = error.response ? error.response.data : error.message;
            } else {
                errorMessage = (error as Error).message;
            }
            return {data: null, error: errorMessage};
        }
    }

    static getUsers(callback: Function) {
        axiosInstance.get("https://jsonplaceholder.typicode.com/users")
            .then((data) => {
                console.log(data);
                callback(data?.data);
            });
    }

    static RegisterAccount(values: any) {
        const baseURL = `${this.usrApiURL}/register`;
        return axiosInstance.post(baseURL, values);
    }

    static async AccountLogin(values: any) {
        console.log('AccountLogin');
        const baseURL = `${this.usrApiURL}/login`;
        console.log(`values = ${JSON.stringify(values)}`);
        return await axiosInstance.post(baseURL, values)
    }

    static async ValidateEmail(token: string) {
        const baseURL = `${this.usrApiURL}/check-email?token=${token}`;
        const res = await axiosInstance.get(baseURL);
        console.log(res);
        return res;
    }

    static async getCpmEdt(callback: Function) {
        const baseURL = `${appConfig.apiURL}/cmpedt`;
        await axiosInstance.get(baseURL)
            .then((res) => {
                    console.log(res.data["Results"]);
                    callback(res.data["Results"]);
                }
            );
    }

    static async fakeAuthToken(callback: Function): Promise<void> {
        const baseURL = `${this.usrApiURL}/token`;
        return await axiosInstance.get(baseURL)
            .then(async (res) => {
                    console.log('result', res.data.data);
                    await callback(res.data.data);
                }
            );
    }

    static async getGroups(callback: Function, competition: number): Promise<void> {
        const baseURL = `${appConfig.apiURL}/groups?cmpEdt=${competition}`;
        return await axiosInstance.get(baseURL)
            .then(async (res) => {
                console.log('result', res.data);
                await callback(res.data);
            });
    }

    static matchesApiURL: string = `${appConfig.apiURL}/matches`

    static async getMatches(callback: Function, competition: number): Promise<void> {
        const baseURL = `${this.matchesApiURL}?cmpEdt=${competition}`;
        console.log(baseURL)
        return await axiosInstance.get(baseURL)
            .then(async (res) => {
                    await callback(res.data);
                }
            );
    }

    static async UpdateMatch(match: IMatch, matchteam_idt: number, value: any) {
        const baseURL = `${this.matchesApiURL}`;
        console.log('UpdateMatch : ', baseURL)
        const values = {
            match: match,
            matchteam: matchteam_idt,
            value: value
        }
        return await axiosInstance.post(baseURL, values)
    }

    static async getClassement(callback: Function, competition: number): Promise<void> {
        const baseURL = `${this.usrApiURL}/classement?cmpEdt_Idt=${competition}`;
        console.log(baseURL)
        return await axiosInstance.get(baseURL)
            .then(async (res) => {
                    await callback(res.data);
                }
            );
    }

    static async getUserBets(callback: Function, competition: number, usr: string | undefined, match?: number): Promise<void> {
        if(usr === undefined || match === undefined)
            return ;
        const baseURL = `${this.usrbetApiURL}?cmpedt_idt=${competition}&username=${usr}` + (match ? `&match=${match}` : '');
        return await axiosInstance.get(baseURL)
            .then(async (res) => {
                    await callback(res.data);
                }
            );
    }
}

export default AxiosServices;
