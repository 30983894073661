import React, {CSSProperties, FC} from "react";
import {Space} from "antd";
import ScoreInput from "./ScoreInput";
import {useAuth} from "../../config/AuthContext";
import {HasMatchStarted, IMatch} from "../../Interfaces/IMatch";

interface Props {
    disable: boolean,
    testMatchStarted?: boolean,
    match: IMatch,
}

const ShowResult: FC<Props> = (props: Props) => {

    const handleInputChange = (value: any) => {
        console.log('changed', value);
    };

    const {isAdmin} = useAuth();

    const scoreDom = (props.disable ? props.match.Score_Dom : null)
    const scoreExt = (props.disable ? props.match.Score_Ext : null)

    const disable = !isAdmin && (props.disable || HasMatchStarted(props.match));

    return (
        <Space>
            <ScoreInput score={scoreDom} disable={disable} callback={handleInputChange}/>
            <ScoreInput score={scoreExt} disable={disable} callback={handleInputChange}/>
        </Space>
    );
}
export default ShowResult;