import {Image, Space} from 'antd';
import {DomExt, ITeam} from "./ITeam";
import './Team.css'
import {FC} from "react";

interface ThisProps {
    team: ITeam;
}

const Team: FC<ThisProps> = ({team}) => {

    const imageStyle = {
        width: '200px',
        height: '200px',
        objectFit: 'cover',
    };

    return (team.DomExt === DomExt.DOM) ?
        <Space direction={"vertical"}>
            <div className="Team">{team.Nom}</div>
            {team.BetScore !== undefined && (
                <p>User Bet Score: {team.BetScore}</p>
            )}
            <Image className="image" src={`/images/${team.Idt}.png`} preview={false}/>
        </Space> :
        <Space direction={"vertical"}>
            <div className="Team">{team.Nom}</div>
            <Image className="image" src={`/images/${team.Idt}.png`} preview={false}/>
        </Space>
}

export default Team;