import {createContext, useEffect, useState} from "react";
import AxiosServices from "../../Services/axiosServices";
import {Modal} from "antd";
import {showErrorModal, showMessageModal} from "../../Services/ModalServices";
import {useNavigate} from "react-router-dom";

const EmailVerify = () => {

    const [modal, contextHolder] = Modal.useModal();
    const navigate = useNavigate();

    const ReachableContext = createContext<string | null>(null);
    const UnreachableContext = createContext<string | null>(null);

    const config = {
        title: 'Erreur !',
        content: (
            <>
                <ReachableContext.Consumer>{(name) => `${error}!`}</ReachableContext.Consumer>
                {/*<br/>*/}
                {/*<UnreachableContext.Consumer>{(name) => `Unreachable: ${name}!`}</UnreachableContext.Consumer>*/}
            </>
        ),
    };

    const [token, SetToken] = useState("");

    const [data, setData] = useState<any | null>(null);
    const [error, setError] = useState<string | null>(null);

    const handleFetchData = async (token: string) => {
        const result = await AxiosServices.validateToken(token);
        if (result.error) {
            showErrorModal(result.error)
            navigate("/");
        } else {
            setData(result.data);
            showMessageModal("Votre inscription est finalisée, vous pouvez dorénavant vous connecter.")
            navigate("/users/login");
        }
    };

    useEffect(() => {
        const queryParameters = new URLSearchParams(window.location.search)
        const token = queryParameters.get("token");

        if (token !== null) {
            SetToken(token);
            handleFetchData(token)
                .then(() => {
                })
                .catch(() => {
                });
        }
    }, []);

    return (<>
            <h1> Vérification d'email</h1><h2>sur le token {token} en cours...</h2>
            {data && (
                <div>
                    <h2>Data:</h2>
                    <pre>{JSON.stringify(data, null, 2)}</pre>
                </div>
            )}
        </>
    );
}
export default EmailVerify;