import React, {FC, useEffect, useState} from "react";
import {Space} from "antd";
import ScoreInput from "./ScoreInput";
import {useAuth} from "../../config/AuthContext";
import {HasMatchStarted, IMatch} from "../../Interfaces/IMatch";
import {DomExt} from "../Team/ITeam";
import AxiosServices from "../../Services/axiosServices";
import axios from "axios";
import CurrentData from "../../config/CurrentData";
import {fetchUserBets} from "../betsSlice";
import {IUserBets} from "../../Interfaces/IUserBets";

interface Props {
    disable: boolean,
    testMatchStarted?: boolean,
    match: IMatch,
}

export interface ISaisie {
    dom?: number;
    ext?: number;
}

const SaisieScores: FC<Props> = (props: Props) => {

    const {isAdmin, authUser, isLoggedIn} = useAuth()
    const [bets, setUserBets] = useState<IUserBets[]>([]);

    useEffect(() => {
        if(isLoggedIn && authUser) {
            AxiosServices.getUserBets(setUserBets, CurrentData.CmpEdt_Idt, authUser?.username, props.match.Idt)
                .then()
                .catch(err => {
                    console.log(err);
                });
        }
    }, [isLoggedIn, authUser]);

    const handleInputChange = async (value: any, match: IMatch, etDomExt: DomExt) => {
        const team: string = (etDomExt === DomExt.DOM) ? match.Nom_Dom : match.Nom_Ext;
        const matchteam_idt: number = (etDomExt === DomExt.DOM) ? match.MatchTeam_Idt_Dom : match.MatchTeam_Idt_Ext;
        console.log(`changed ${match.Idt} ${matchteam_idt} ${team} ${value}`);

        try {
            await AxiosServices.UpdateMatch(match, matchteam_idt, value);
        } catch (e: unknown) {
            if (axios.isAxiosError(e)) {
                console.error(e.message)
            } else {
                console.error(e);
            }
        }

    };
    const handleInputChangeDom = async (value: any) => {
        await handleInputChange(value, props.match, DomExt.DOM);
    };
    const handleInputChangeExt = async (value: any) => {
        await handleInputChange(value, props.match, DomExt.EXT);
    };

    const scoreDom = bets.filter((bet: IUserBets) => bet.matchteam_idt === props.match.MatchTeam_Idt_Dom)[0]?.score;
    const scoreExt = bets.filter((bet: IUserBets) => bet.matchteam_idt === props.match.MatchTeam_Idt_Ext)[0]?.score;

    const disable = (props.disable || HasMatchStarted(props.match) || isAdmin) || (!isLoggedIn && !isAdmin);

    return (
        <Space>
            <ScoreInput score={scoreDom} disable={disable} callback={handleInputChangeDom}/>
            <ScoreInput score={scoreExt} disable={disable} callback={handleInputChangeExt}/>
        </Space>
    );
}
export default SaisieScores;