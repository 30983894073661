import {FC} from 'react';

export const Results: FC = () => {
    return <div className="overview">Résultats</div>;
};

export const Standings: FC = () => {
    return <div className="overview">Classements</div>;
};

export const Users: FC = () => {
    return <div className="overview">Utilisateurs</div>;
};

export const UsersResults: FC = () => {
    return <div className="overview">Résultats utilisateurs</div>;
};

export const UsersStandings: FC = () => {
    return <div className="overview">Classements utilisateurs</div>;
};

export const History: FC = () => {
    return <div className="overview">History</div>;
};

export const Configurations: FC = () => {
    return <div className="overview">Configurations</div>;
};