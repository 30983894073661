import React, {FC, useState} from 'react';
import {Link} from 'react-router-dom';
import styled from 'styled-components';
import {SidebarItem} from '../models/SidebarItem';
import {FaRegMinusSquare, FaRegPlusSquare} from "react-icons/fa";

type SidebarLinkProps = {
    item: SidebarItem;
};

const SidebarLink = styled(Link)`
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 1rem;
    font-size: 1rem;
    padding: 1rem;
    margin-top: 20px;
    text-decoration: none;
    color: darkslategrey;

    &:hover {
        background-color: royalblue;
        color: white;
        border-left: 4px solid #6d44dc;
    }
`;

const SidebarLabel = styled.span`
    margin-left: 1rem;
`;

const DropdownLink = styled(Link)`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 1rem;
    font-size: 1rem;
    padding-left: 1.5rem;
    margin-bottom: 10px;
    text-decoration: none;
    color: gray;

    &:hover {
        background-color: royalblue;
        color: white;
        border-left: 5px solid #6d44dc;
    }
`;

const Submenu: FC<SidebarLinkProps> = ({item}) => {
    const [subnav, setSubnav] = useState(false);
    const showSubnav = () => setSubnav(!subnav);

    const iconClosed: JSX.Element = <FaRegPlusSquare/>;
    const iconOpened: JSX.Element = <FaRegMinusSquare/>;

    return (
        <>
            <SidebarLink to={item.path} onClick={showSubnav}>
                <div>
                    {item.icon}
                    <SidebarLabel>{item.title}</SidebarLabel>
                </div>
                <div>{item?.subnav ? (subnav ? iconOpened : iconClosed) : null}</div>
            </SidebarLink>
            {subnav &&
                item?.subnav?.map((subnavItem, index) => {
                    return (
                        <DropdownLink to={subnavItem.path} key={index}>
                            {subnavItem.icon}
                            <SidebarLabel>{subnavItem.title}</SidebarLabel>
                        </DropdownLink>
                    );
                })}
        </>
    );
};

export default Submenu;