import React from 'react';
import {Modal} from 'antd';
import './ModalServices.css';

// Utility function to show error modal with customization
export function showErrorModal(message: string) {
    Modal.error({
        title: 'Erreur lors du traitement !',
        className: 'custom-error-modal ant-modal',
        content: (
            <>
                <p>{message}</p>
            </>
        ),
        onOk() {
            console.log('OK clicked');
        },
    });
}

// Utility function to show error modal with customization
export function showMessageModal(message: string) {
    Modal.info({
        title: 'Traitement terminé.',
        className: 'custom-info-modal ant-modal',
        content: (
            <>
                <p>{message}</p>
            </>
        ),
        onOk() {
            console.log('OK clicked');
        },
    });
}
